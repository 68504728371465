import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';

interface DataPoint {
  name: string;
  value: number;
}

const data: DataPoint[] = [
  { name: '1', value: 30 },
  { name: '2', value: 70 },
  { name: '3', value: 20 },
  { name: '4', value: 90 },
  { name: '5', value: 50 },
  { name: '6', value: 40 },
  { name: '7', value: 60 },
  { name: '8', value: 20 },
  { name: '9', value: 80 },
  { name: '10', value: 60 },
];

const MobileBarChartComponent: React.FC = () => {
  return (
    <div 
      style={{
        backgroundColor: '#ff4d4d',
      }}
      className='responsive-chart'
    >
      {/* Title and Amount */}
      <div
        className="chart-info-mob"
        style={{
          textAlign: 'center',
          color: '#fff',
        }}
      >
        <h3 style={{ margin: '0', fontSize: '14px' }}>Bank</h3>
        <p style={{ margin: '0', fontSize: '15px' }}>₼361,454.00</p>
      </div>
      {/* Chart Container */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <ResponsiveContainer width="90%" height="100%">
          <BarChart
            data={data}
            margin={{
              top: 5,
              right: 5,
              left: 5,
              bottom: 5,
            }}
          >
            <CartesianGrid stroke="none" />
            <XAxis
              dataKey="name"
              tick={false}
              axisLine={false}
              tickLine={false}
            />
            <YAxis
              tick={false}
              axisLine={false}
              width={0} // Removes left padding for Y-axis
            />
            <Bar dataKey="value" fill="#ffffff" barSize={5} radius={[2, 2, 0, 0]} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default MobileBarChartComponent;
