import React, { useState } from 'react'
import PersonIDCardSvg from '../svg/PersonIDCardSvg'
import ArrowDownSvg from '../svg/ArrowDownSvg'
import FlagAz from '../svg/FlagAz'
import LogOutSvg from '../svg/LogOutSvg'

function LogOut() {
  const [dropVisible, setDropVisible] = useState<boolean>(false)
  return (
    <div className='dropdown'>
        <button onClick={()=>setDropVisible(!dropVisible)} className={dropVisible? 'dropdown-head-end dropdown-head': 'dropdown-head-end'}> 
            <PersonIDCardSvg/>
            <span>Xan Group</span>
            <ArrowDownSvg/>
        </button>
        {
          dropVisible?
          <div className="dropdown-content-logout">
          <div className="flags">
            <div className="flag">
              <div className="flag-circle">
                <FlagAz/>
              </div>
              <p>AZ</p>
            </div>
            <div className="flag">
              <div className="flag-circle">
                <FlagAz/>
              </div>
              <p>AZ</p>
            </div>
            <div className="flag">
              <div className="flag-circle">
                <FlagAz/>
              </div>
              <p>AZ</p>
            </div>
          </div>
          <button className='logout-btn'>
            <span>Çıxış</span>
            <LogOutSvg color="#0D3558"/>
          </button>
        </div> : null
        }

    </div>
  )
}

export default LogOut
