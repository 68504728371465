import React from 'react'

function Percentage() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
  >
    <path
      className='svg-color'
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M12 2.667h8c6.667 0 9.333 2.666 9.333 9.333v8c0 6.667-2.666 9.333-9.333 9.333h-8c-6.667 0-9.333-2.666-9.333-9.333v-8c0-6.667 2.666-9.333 9.333-9.333ZM11.427 20.36l8.72-8.72"
    />
    <path
      className='svg-color'
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M11.973 13.827a1.64 1.64 0 1 0 0-3.28 1.64 1.64 0 0 0 0 3.28ZM20.693 21.453a1.64 1.64 0 1 0 0-3.28 1.64 1.64 0 0 0 0 3.28Z"
    />
  </svg>
  )
}

export default Percentage
