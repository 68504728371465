import React, { useState } from "react";
import FlagAz from "../svg/FlagAz";
import PersonIDCardSvg from "../svg/PersonIDCardSvg";
import LogOutSvg from "../svg/LogOutSvg";
import ArrowUpSvg from "../svg/ArrowUpSvg";
import { ReduxTypes } from '../../type_models/ReduxTypes';
import { changeStateValue } from '../../redux/MainReducer'
import { useDispatch, useSelector } from "react-redux";


function MobilSettingModal(){
  const modalSettingVisible = useSelector((state:ReduxTypes)=> state.Data.modalSettingVisible)
  const dispatch = useDispatch<any>();
  return (
    <div className="fixed-bottom-modal">
      <div className="selection-head">
      <div className="modal-top">
        <h2>Ayarlar</h2>
        <button className="close-button" onClick={()=>dispatch(changeStateValue({name:"modalSettingVisible", value: false}))}>
         <img src="./images/close btn.png" alt="close" />
        </button>
      </div>
        <div className="personal-logout">
         <div className="personal-info">
          <PersonIDCardSvg />
          <span className="group-name">Xan Group</span>
         </div>
         <button className="logout">
          <LogOutSvg color="#FAFAFA"/>
         </button>
        </div>
      </div>

      <div className="flags-container">
        <div className="flag">
          <div className="flag-circle">
            <FlagAz/>
          </div>
          <p>AZ</p>
        </div>
        <div className="flag">
          <div className="flag-circle">
            <FlagAz/>
          </div>
          <p>AZ</p>
        </div>
        <div className="flag">
          <div className="flag-circle">
            <FlagAz/>
          </div>
          <p>AZ</p>
        </div>
      </div>

      <div className="flags-container">
        <div className="flag">
          <span className="currency-symbol">₼</span>
         <div className="currency-rate">
          <span>1.00</span>
          <ArrowUpSvg />
         </div>
        </div>
        <div className="flag">
          <span className="currency-symbol">$</span>
          <div className="currency-rate">
            <span>1.00</span>
            <ArrowUpSvg />
         </div>
        </div>
        <div className="flag">
          <span className="currency-symbol">€</span>
          <div className="currency-rate">
            <span>1.00</span>
            <ArrowUpSvg />
         </div>
        </div>
      </div>
    </div>
  );
};



export default MobilSettingModal;
