import React from 'react'

function TagSvg() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
  >
    <path
      className='svg-color'
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m5.56 20.4 6.04 6.04c2.48 2.48 6.507 2.48 9 0l5.853-5.853c2.48-2.48 2.48-6.507 0-9L20.4 5.56a6.334 6.334 0 0 0-4.8-1.853l-6.667.32a5.152 5.152 0 0 0-4.92 4.893l-.32 6.667c-.08 1.8.6 3.546 1.867 4.813Z"
    />
    <path
      className='svg-color'
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M12.666 16a3.333 3.333 0 1 0 0-6.667 3.333 3.333 0 0 0 0 6.667Z"
    />
  </svg>
  )
}

export default TagSvg
