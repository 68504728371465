import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const ForgetPasswordPage = () => {

  return (
    <div className="container">
      <div className="login-container">
      <div className="left-panel">
        <h1>Reporto</h1>
        <p>
        Lorem ipsum dolor sit ame onsectetur.Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit ame onsectetur.
        </p>
      </div>
      <div className="right-panel">
        <h2>Şifrəni unutdum</h2>
        <form>
          <div className="input-group">
            <label htmlFor="username">Elektron poçt</label>
            <div className='input'>
              <input  type="text" id="username" placeholder="Elektron poçt" />
            </div>
          </div>
          
          <button type="submit" className="login-button">Göndər</button>
        </form>
        <Link to="/login" className="link">
        Giriş
        </Link>
      </div>
    </div>
    </div>
  );
};

export default ForgetPasswordPage;
