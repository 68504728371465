import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from './components/login/Login';
import ForgetPassword from './components/login/ForgetPassword';
import NoInformationPage from './pages/NoInformationPage';
import HomePage from './pages/HomePage';
import CashPage from './pages/CashPage';
import CreditorPage from './pages/CreditorPage';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxTypes } from './type_models/ReduxTypes';
import { changeStateValue } from './redux/MainReducer';
import MobilNavbar from './components/mobile/MobilNavbar';
import MobilTabBar from './components/mobile/MobilTabBar';
import Navbar from './components/Navbar';
import Header from './components/header/Header';
import MobilSelectModal from './components/mobile/MobilSelectModal';
import MobilSettingModal from './components/mobile/MobilSettingModal';
import ModalInfo from './components/ModalInfo';

function App() {
  const navVisible = useSelector((state:ReduxTypes)=> state.Data.navVisible)
  const mobileDesign = useSelector((state:ReduxTypes) => state.Data.mobileDesign);
  const modalSelectVisible = useSelector((state:ReduxTypes) => state.Data.modalSelectVisible);
  const modalSettingVisible = useSelector((state:ReduxTypes) => state.Data.modalSettingVisible);
  const modalInfoVisible = useSelector((state:ReduxTypes) => state.Data.modalInfoVisible);

  const dispatch = useDispatch<any>()
  console.log(mobileDesign)


  return (
    <div className='page'>
      {
        window.innerWidth <= 600 ? 
        <>
          <MobilNavbar/>
        </>  : <Navbar />
      }
      { window.innerWidth <= 600 && navVisible ?  <Navbar/> :null  }
      <main>
        <header>
          <Header/>
        </header>   
        <Routes>
          <Route path='/noinfo' element={<NoInformationPage />}/>
          <Route path='/' element={<HomePage />}/>
          <Route path='/cash' element={<CashPage />}/>
          <Route path='/creditor' element={<CreditorPage />}/>
          <Route path='/login' element={<Login />}/>
          <Route path='/forgetpassword' element={<ForgetPassword />}/>
        </Routes>
      </main>
  
     {
      modalSelectVisible? 
      <MobilSelectModal/> :null
     }
     {
      modalSettingVisible? 
      <MobilSettingModal/> :null
     }  
     {
      modalInfoVisible? 
      <ModalInfo/> :null
     }  


    {
      window.innerWidth <= 600 ? 
        <MobilTabBar/>
      :null
    }
      
    </div>
   
  );
}

export default App;
