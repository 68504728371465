import React, { useEffect } from 'react'
import MainTop from '../components/MainTop'
import TableView from '../components/TableView'
import { ReduxTypes } from '../type_models/ReduxTypes'
import { useDispatch, useSelector } from 'react-redux'
import GraphicView from '../components/GraphicView'
import { changeStateValue } from '../redux/MainReducer'

function CashPage() {
  const tableVisible = useSelector((state:ReduxTypes)=> state.Data.tableVisible)
  const dispatch = useDispatch<any>();
  useEffect(() => {
    return () => {
      dispatch(changeStateValue({ name: "tableVisible", value: false }));
    };
  }, []);
  return (
    <>
      <MainTop/>
      <>
        {
          tableVisible?  <TableView/> : <GraphicView/>
        }
      </>
    </>
  )
}

export default CashPage
