import React, { useState } from 'react'
import ArrowDownSvg from '../svg/ArrowDownSvg'
import CurrencyAZN from '../svg/CurrencyAZN'


function Currency() {
  const [dropVisible, setDropVisible] = useState<boolean>(false)

  return (
    <div className='dropdown'>
    <button onClick={()=>setDropVisible(!dropVisible)} className={dropVisible? 'dropdown-head dropdown-head_hover': 'dropdown-head'}> 
      <div className='dropdown-left'>
        <div className='currency'>
          <CurrencyAZN/>
        </div>
        <span>1.00</span>
      </div>
      <div className='dropdown-svg'>
        <ArrowDownSvg/>
      </div>
    </button>
    {
      dropVisible?
      <ul className="dropdown-content-currency">
      <li>
        <div className='currency'>
          <CurrencyAZN/>
        </div>
        <span>1.00</span>
      </li>
      <li>
        <div className='currency'>
          <CurrencyAZN/>
        </div>
        <span>1.00</span>
      </li>
      <li>
        <div className='currency'>
          <CurrencyAZN/>
        </div>
        <span>1.00</span>
      </li>
    </ul>:null
    }
  </div>
  )
}

export default Currency
