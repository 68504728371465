import * as React from "react"
const EyesSlishSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
  >
    <path fill="#AFAFAF" d="M0 0h24v24H0z" />
    <path fill="#F6F6F6" d="M-1071-530H369V494h-1440z" />
    <g filter="url(#a)">
      <path
        fill="#fff"
        d="M-362-247c0-17.673 14.327-32 32-32H42c17.673 0 32 14.327 32 32v459c0 17.673-14.327 32-32 32h-372c-17.673 0-32-14.327-32-32v-459Z"
        shapeRendering="crispEdges"
      />
      <rect width={372} height={52} x={-330} y={-14} fill="#F5F5F5" rx={15} />
      <g
        stroke="#767676"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      >
        <path d="m9.47 9.47 5.06 5.06a3.576 3.576 0 1 0-5.06-5.06Z" />
        <path d="M6.18 5.77C7.93 4.45 9.93 3.73 12 3.73c3.53 0 6.82 2.08 9.11 5.68.9 1.41.9 3.78 0 5.19-.79 1.24-1.71 2.31-2.71 3.17M15.58 19.53c-1.14.48-2.35.74-3.58.74-3.53 0-6.82-2.08-9.11-5.68-.9-1.41-.9-3.78 0-5.19.33-.52.69-1.01 1.06-1.47" />
        <path d="M8.49 12.7a3.565 3.565 0 0 0 2.82 2.82M14.53 14.53 22 22M2 2l7.47 7.47" />
      </g>
    </g>
    <defs>
      <filter
        id="a"
        width={486}
        height={573}
        x={-387}
        y={-300}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={12.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.0509804 0 0 0 0 0.207843 0 0 0 0 0.345098 0 0 0 0.25 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_0_1" />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_0_1"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)
export default EyesSlishSvg
