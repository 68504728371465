import React from 'react'
import MenuSvg from '../svg/MenuSvg'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxTypes } from '../../type_models/ReduxTypes';
import { changeStateValue } from '../../redux/MainReducer';

function MobilNavbar() {
  const dispatch = useDispatch<any>();
  return (
    <div className='mobile'>
      <div className='mob-top'>
        <button>
            <span>Left Title</span>
        </button>
        <h3>Title</h3>
        <button>
            <span>Right Title</span>
        </button>
      </div>
      <div className='mob-navbar'>
        <h1>Reporto</h1>
        <button onClick={()=>dispatch(changeStateValue({name:"navVisible", value: true}))} >
            <MenuSvg color="#FFFFFF"/>
        </button>
      </div>
    </div>
  )
}

export default MobilNavbar
