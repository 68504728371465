import React, { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

interface DataPoint {
  name: string;
  value: number;
}

const data: DataPoint[] = [
  { name: 'Jan', value: 20 },
  { name: 'Feb', value: 10 },
  { name: 'Mar', value: 45 },
  { name: 'Apr', value: 20 },
  { name: 'May', value: 80 },
  { name: 'Jun', value: 100 },
];

const ChartComponent: React.FC = () => {
  const [fontSize, setFontSize] = useState('16px');
  const [fontSizeTop, setfontSizeTop] = useState('20px');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 400) {
        setFontSize('9px');
        setfontSizeTop('12px');
      } else if (window.innerWidth <= 800) {
        setfontSizeTop('16px');
        setFontSize('12px');
      } else {
        setfontSizeTop('20px');
        setFontSize('16px');
      }
    };

    handleResize(); // Set initial value
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div className='chart-card ' style={{ backgroundColor: '#249BF4' }}>
      <div className='chart-info'>
        <h3 style={{fontSize:fontSize}}>Kassa</h3>
        <p>₼361,454.00</p>
      </div>
      <ResponsiveContainer width="100%" maxHeight={250}  height='80%'>
        <LineChart data={data} margin={{ top: 0, right: 0, left: 0, bottom: 0 }} >
          <CartesianGrid stroke="#ffffff" strokeDasharray="3 3" vertical={false} />
          <XAxis 
            dataKey="name"
            tick={false}
            stroke="#fff"
          />  {/* Hide x-axis labels */}
          <YAxis
            tick={{ fill: '#ffffff' }}
            stroke="none"
            ticks={[0, 25, 50, 75, 100]}
            domain={[0, 120]}
            width={45} 
            fontSize={fontSize}
          />
          {/* <Tooltip /> */}
          <Line type="linear" dataKey="value" stroke="#ffffff"  strokeWidth={2} dot={false}/>
          {/* monotone */}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ChartComponent;
