import React from 'react'

function VOENSvg() {
  return (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={30}
        height={30}
        fill="none"
    >
        <path
        stroke="#249BF4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M22.5 23.575h-.95c-1 0-1.95.387-2.65 1.088l-2.137 2.112a2.528 2.528 0 0 1-3.538 0l-2.137-2.112a3.756 3.756 0 0 0-2.65-1.088H7.5c-2.075 0-3.75-1.662-3.75-3.712V6.213C3.75 4.163 5.425 2.5 7.5 2.5h15c2.075 0 3.75 1.662 3.75 3.713V19.85c0 2.05-1.675 3.725-3.75 3.725Z"
        />
        <path
        stroke="#249BF4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M15.087 11.188H14.9a2.431 2.431 0 0 1-2.35-2.438 2.433 2.433 0 0 1 2.437-2.438 2.44 2.44 0 0 1 2.438 2.438 2.404 2.404 0 0 1-2.338 2.438ZM11.562 14.95c-1.662 1.113-1.662 2.925 0 4.038 1.888 1.262 4.988 1.262 6.875 0 1.663-1.113 1.663-2.925 0-4.038-1.887-1.25-4.975-1.25-6.875 0Z"
        />
    </svg>
  )
}

export default VOENSvg
