import React from 'react'
import ChartComponent from '../components/chartSetting/ChartComponent' 
import BarChartComponent from '../components/chartSetting/BarChartComponent' 
import MobileBarChartComponent from '../components/chartSetting/MobileBarChartComponent'
import MobileChartComponent from '../components/chartSetting/MobileChartComponent'

function HomePage() {
  return (
    <>
      <div className='charts'>
      { window.innerWidth <= 600  ? 
      <> 
      <MobileBarChartComponent/>
      <MobileChartComponent/>
      <MobileBarChartComponent/>
      <MobileChartComponent/>
      <MobileBarChartComponent/>
      <MobileChartComponent/>
      <MobileBarChartComponent/>
      <MobileChartComponent/>
      <MobileBarChartComponent/>
      <MobileChartComponent/>
      </>  :
      <>
      <ChartComponent />
       <BarChartComponent/>
       <ChartComponent/>
       <BarChartComponent/>
       <ChartComponent/>
       <BarChartComponent/>
       <ChartComponent/>
       <BarChartComponent/>
     </>
      }
      </div>
    </>
  )
}

export default HomePage
