import Companies from './Companies'
import Months from './Months'
import Exchange from './Currency'
import LogOut from './LogOut'
import ScanSvg from '../svg/ScanSvg'
import MenuSvg from '../svg/MenuSvg'
import { useEffect } from 'react'


function Header() {
  
  const toggleFullscreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen(); 
    }
  };

  return (
    <div className='header'>
        <div className="header-top">
          <div className='left-container'>
              <Companies />
              <Months/>
              <Exchange/>
          </div>
          <button className='menu-tablet'>
            <MenuSvg color='#0D3558'/>
          </button>
          <div className='right-container'>
              <button  onClick={toggleFullscreen}>
                <ScanSvg/>
              </button>
              <LogOut/>
          </div>
        </div>
    </div>
  )
}

export default Header
