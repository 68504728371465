import * as React from "react"
const ThumbtackSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13}
    height={17}
    fill="none"
  >
    <path
      fill="#fff"
      d="M9.813 6.719c1.5.687 2.687 1.937 2.687 3.531a.74.74 0 0 1-.75.75H7.5v3.281c0 .031-.031.063-.031.094l-.75 1.5c-.094.188-.375.188-.469 0l-.75-1.5V11H1.25a.722.722 0 0 1-.75-.75c0-1.563 1.156-2.844 2.656-3.531L3.562 3H2.25a.722.722 0 0 1-.75-.75V.75A.74.74 0 0 1 2.25 0h8.5a.76.76 0 0 1 .75.75v1.5a.74.74 0 0 1-.75.75H9.406l.406 3.719Z"
    />
  </svg>
)
export default ThumbtackSvg