import React from 'react'

function ChartSvg() {
  return (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        fill="none"
    >
        <path
        className='svg-color'
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M5.36 7.96A13.289 13.289 0 0 0 2.667 16c0 7.36 5.973 13.333 13.333 13.333S29.333 23.36 29.333 16 23.36 2.667 16 2.667"
        />
        <path
        className='svg-color'
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M6.667 16A9.327 9.327 0 0 0 16 25.333 9.326 9.326 0 0 0 25.333 16 9.326 9.326 0 0 0 16 6.667"
        />
        <path
        className='svg-color'
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M16 21.333A5.332 5.332 0 0 0 21.333 16 5.332 5.332 0 0 0 16 10.667"
        />
    </svg>
  )
}

export default ChartSvg
