import React, { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

interface DataPoint {
  date: string;
  balance: number;
}

const data: DataPoint[] = [
  { date: '1.10.2024', balance: 80 },
  { date: '2.10.2024', balance: 85 },
  { date: '3.10.2024', balance: 100 },
  { date: '4.10.2024', balance: 90 },
  { date: '5.10.2024', balance: 75 },
  { date: '6.10.2024', balance: 65 },
  { date: '7.10.2024', balance: 70 },
  { date: '8.10.2024', balance: 90 },
  { date: '9.10.2024', balance: 95 },
  { date: '10.10.2024', balance: 85 },
];

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    const { date, balance } = payload[0].payload;

    return (
      <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#fff', borderRadius: '12px', padding: '10px' }}>
        <div
          style={{
            background: '#0D3558',
            borderRadius: '12px 12px 0 0',
            padding: '7px',
            color:'#fff',
            fontSize: '9px'
          }}
        >
          <strong>Date: </strong> {date}
        </div>
        <div
          style={{
            background: '#0D3558',
            borderRadius: '0 0 12px 12px',
            color:'#fff',
            padding: '7px',
            boxShadow: '0px 4px 10px 0px rgba(14, 73, 113, 0.10)',
            fontSize: '9px'
          }}
        >
          <strong>Balance: </strong> {balance} ₼
        </div>
      </div>
    );
  }

  return null;
};

const SingleChartComponent: React.FC = () => {
  const [fontSize, setFontSize] = useState('16px');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 400) {
        setFontSize('9px');
      } else if (window.innerWidth <= 800) {
        setFontSize('12px');
      } else {
        setFontSize('16px');
      }
    };

    handleResize(); // Set initial value
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="chart-card-single">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={data} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
          <CartesianGrid stroke="#EDEFF1" strokeDasharray="3 3" vertical={false} />
          <XAxis
            fontSize={fontSize}
            dataKey="date"
            tick={{ fill: '#A8A8A8' }}
            stroke="#fff"
          /> {/* Hide x-axis labels */}
          <YAxis
            fontSize={fontSize}
            tick={{ fill: '#A8A8A8' }}
            stroke="none"
            ticks={[0, 25, 50, 75, 100]}
            domain={[0, 120]}
            width={45}
          />
          <Tooltip content={<CustomTooltip />} />
          {/* Modify the Line component to create a dotted line */}
          <Line type="linear"  dataKey="balance" stroke="#031C30" strokeWidth={2} dot={false} strokeDasharray="3 3" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SingleChartComponent;
