import React, { useState } from 'react'
import InfoCircleSvg from '../svg/InfoCircleSvg'
import ArrowDownSvg from '../svg/ArrowDownSvg'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxTypes } from '../../type_models/ReduxTypes'
import { changeStateValue } from '../../redux/MainReducer'

function Companies() {
  const [dropVisible, setDropVisible] = useState<boolean>(false)
  const modalInfoVisible = useSelector((state:ReduxTypes)=> state.Data.modalInfoVisible)
  const dispatch = useDispatch<any>();
  return (
    <div className='dropdown'>
      <button  className={dropVisible? 'dropdown-head dropdown-head_hover': 'dropdown-head'}> 
        <div className='dropdown-left'>
          <button className='info-btn' onClick={()=>dispatch(changeStateValue({name:"modalInfoVisible", value: !modalInfoVisible}))}>
            <InfoCircleSvg />
          </button>
          <div className='dropdown-left' onClick={()=>setDropVisible(!dropVisible)}>
          <span>Saat Store</span>
          <div className='dropdown-svg '>
            <ArrowDownSvg/>
          </div>
          </div>
        </div>
      </button>
      {
        dropVisible?
        <ul className="dropdown-content">
          <li>
            <span>Saat store</span>
          </li>
          <li>
            <span>Saat store</span>
          </li>
          <li>
            <span>Saat store</span>
          </li>
          <li>
            <span>Saat store</span>
          </li>
        </ul>:null
      }
     
    </div>
  )
}

export default Companies
