import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';

interface DataPoint {
  name: string;
  value: number;
}

const data: DataPoint[] = [
  { name: '1', value: 30 },
  { name: '2', value: 70 },
  { name: '3', value: 20 },
  { name: '4', value: 90 },
  { name: '5', value: 50 },
  { name: '6', value: 40 },
  { name: '7', value: 60 },
  { name: '8', value: 20 },
  { name: '9', value: 80 },
  { name: '10', value: 60 },
];

// Extract only the "name" values for the X-axis labels
const xAxisLabels = data.map((item) => item.name);

const BarChartComponent: React.FC = () => {
  const [fontSize, setFontSize] = useState('16px');
  const [fontSizeTop, setfontSizeTop] = useState('20px');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 400) {
        setFontSize('9px');
        setfontSizeTop('12px');
      } else if (window.innerWidth <= 800) {
        setfontSizeTop('16px');
        setFontSize('12px');
      } else {
        setfontSizeTop('20px');
        setFontSize('16px');
      }
    };

    handleResize(); // Set initial value
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div style={{ backgroundColor: '#ff4d4d' }} className="chart-card">
      <div className="chart-info">
        <h3 style={{fontSize:fontSize}}>Bank</h3>
        <p>₼361,454.00</p>
      </div>
      <ResponsiveContainer width="100%" maxHeight={250} height='80%'>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="1 1" vertical={false} strokeWidth={1} />
          <XAxis 
            dataKey="name" 
            tick={{ fill: '#A8A8A8' }}  // Set tick color to gray
            stroke="#fff"
            fontSize={fontSize}
            ticks={xAxisLabels}  
          />
          <YAxis 
            tick={{ fill: '#fff' }}  // Set tick color to gray
            stroke="none"
            width={40}
            fontSize={fontSize}
          />
          <Bar dataKey="value" fill="#ffffff" barSize={5} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarChartComponent;
