import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';

interface DataPoint {
  name: string;
  value: number;
}

const data: DataPoint[] = [
  { name: 'Jan', value: 20 },
  { name: 'Feb', value: 10 },
  { name: 'Mar', value: 45 },
  { name: 'Apr', value: 20 },
  { name: 'May', value: 80 },
  { name: 'Jun', value: 100 },
];

const MobileChartComponent: React.FC = () => {
  return (
    <div className='responsive-chart'
      style={{
        backgroundColor: '#249BF4',
      }}
    >
      {/* Title and Amount */}
      <div
        style={{
          textAlign: 'center',
          color: '#fff',
          marginBottom: '0.5rem',
        }}
      >
        <h3 style={{ margin: '0', fontSize: '14px' }}>Kassa</h3>
        <p style={{ margin: '0', fontSize: '15px' }}>₼361,454.00</p>
      </div>
      {/* Chart Container */}
      <div
        style={{
          width: '100%', // Full width of the chart container
          height: '60%', // Allow chart to take 60% of the height
        }}
      >
        <ResponsiveContainer>
          <LineChart data={data} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
            <CartesianGrid stroke="none" strokeDasharray="3 3" vertical={false} />
            <XAxis dataKey="name" tick={false} stroke="none" />
            <YAxis tick={false} stroke="none" width={0} />
            <Line type="linear" dataKey="value" stroke="#ffffff" strokeWidth={2} dot={false} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default MobileChartComponent;
