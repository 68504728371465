import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxTypes } from '../../type_models/ReduxTypes';
import { changeStateValue } from '../../redux/MainReducer';


function MobilSelectModal () {
  const modalSelectVisible = useSelector((state:ReduxTypes)=> state.Data.modalSelectVisible)
  const dispatch = useDispatch<any>();
  return (
    <div className="fixed-bottom-modal">
      <div className="modal-top">
        <h2>Şirkət seçin</h2>
        <button className="close-button" onClick={()=>dispatch(changeStateValue({name:"modalSelectVisible", value: false}))}>
         <img src="./images/close btn.png" alt="close" />
        </button>
      </div>
      <div className="option-list">
            <label >
            <input
              type="radio"
              name="company"
              // value={company.name}
             
            />
            Selected Company
          </label>
            <label >
            <input
              type="radio"
              name="company"
              // value={company.name}
             
            />
            Selected Company
          </label>
            <label >
            <input
              type="radio"
              name="company"
              // value={company.name}
             
            />
            Selected Company
          </label>
            <label >
            <input
              type="radio"
              name="company"
              // value={company.name}
             
            />
            Selected Company
          </label>
      </div>
    </div>
  );
};

export default MobilSelectModal;
