import React from 'react'
 

function MenuSvg({color}) {
  return (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={30}
        height={30}
        fill="none"
    >
        <path
        stroke={color}
        strokeLinecap="round"
        strokeWidth={1.5}
        d="M3.75 8.75h22.5M3.75 15h22.5M3.75 21.25h22.5"
        />
    </svg>
  )
}

export default MenuSvg
