import React from 'react'
import DonutChartExample from './chartSetting/DonutChartExample'
import SingleBarChartComponent from './chartSetting/SingleBarChartComponent'

function GraphicView() {
  return (
    <div className='two-charts'>
       <DonutChartExample/>
       <SingleBarChartComponent/>
    </div>
  )
}

export default GraphicView
