import React, { useState } from 'react';
import VOENSvg from './svg/VOENSvg';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxTypes } from '../type_models/ReduxTypes';
import { changeStateValue } from '../redux/MainReducer';

function ModalInfo() {
  const modalInfoVisible = useSelector((state:ReduxTypes)=> state.Data.modalInfoVisible)
  const dispatch = useDispatch<any>();

  return (
    <div className='modal-info'>
      <div className="modal-info-top">
        <h2>Haqqinda</h2>
        <div className="close-modal">
          <button className='whatsapp'></button>
          <button className='close'  onClick={()=>dispatch(changeStateValue({name:"modalInfoVisible", value: false}))}>
          </button>
        </div>
      </div>
      <div className="modal-info-main">
        <ul>
          <li>
            <div className="left">
              <VOENSvg />
              <h6>Vöen:</h6>
            </div>
            <div className="right">
              <h6>2132569659663</h6>
            </div>
          </li>
          <li>
            <div className="left">
              <VOENSvg />
              <h6>Vöen:</h6>
            </div>
            <div className="right">
              <h5>Kapital Bank:</h5>
              <h6>2132569659663</h6>
            </div>
          </li>
          <li>
            <div className="left">
              <VOENSvg />
              <h6>Vöen:</h6>
            </div>
            <div className="right">
              <h6>2132569659663</h6>
            </div>
          </li>
          <li>
            <div className="left">
              <VOENSvg />
              <h6>Vöen:</h6>
            </div>
            <div className="right">
              <h6>2132569659663</h6>
            </div>
          </li>
          <li>
            <div className="left">
              <VOENSvg />
              <h6>Vöen:</h6>
            </div>
            <div className="right">
              <h6>2132569659663</h6>
            </div>
          </li>
          <li>
            <div className="left">
              <VOENSvg />
              <h6>Vöen:</h6>
            </div>
            <div className="right">
              <h6>2132569659663</h6>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default ModalInfo;
