import React from 'react'

function PersonIDCardSvg() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    fill="none"
  >
    <path
      stroke="#0D3558"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M21.25 26.25H8.75c-5 0-6.25-1.25-6.25-6.25V10c0-5 1.25-6.25 6.25-6.25h12.5c5 0 6.25 1.25 6.25 6.25v10c0 5-1.25 6.25-6.25 6.25ZM17.5 10h6.25M18.75 15h5M21.25 20h2.5"
    />
    <path
      stroke="#0D3558"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M10.625 14.112a2.263 2.263 0 1 0 0-4.525 2.263 2.263 0 0 0 0 4.525ZM15 20.413a3.776 3.776 0 0 0-3.425-3.4 9.65 9.65 0 0 0-1.9 0 3.787 3.787 0 0 0-3.425 3.4"
    />
    </svg>
  )
}

export default PersonIDCardSvg
