import React from 'react'

function ArrowUpSvg() {
  return (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={17}
        height={16}
        fill="none"
    >
        <path
        stroke="#0D3558"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.5}
        d="M12.547 6.38 8.5 2.333 4.453 6.38M8.5 13.667V2.447"
        />
    </svg>
  )
}

export default ArrowUpSvg
