import React from 'react'

function CurrencyAZN() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={10}
    fill="none"
  >
    <path
      className='currency-circle'
      d="M.596 7.592c0-.672.075-1.293.224-1.862.159-.579.41-1.087.756-1.526.345-.439.802-.789 1.372-1.05.57-.261 1.274-.41 2.114-.448V.004h1.904v2.702c.85.037 1.558.173 2.128.406.57.233 1.022.56 1.358.98.345.41.588.91.728 1.498.15.579.224 1.237.224 1.974V10H9.416V7.592c0-.644-.052-1.162-.154-1.554-.103-.401-.257-.714-.462-.938a1.594 1.594 0 0 0-.756-.476 4.798 4.798 0 0 0-1.078-.21V7.62H5.062V4.414a5.38 5.38 0 0 0-1.036.196 1.788 1.788 0 0 0-.77.476c-.215.215-.383.523-.504.924-.112.392-.168.91-.168 1.554V10H.596V7.592Z"
    />
  </svg>
  )
}

export default CurrencyAZN
