import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import './components/css/index.css';
import './components/css/header.css'
import './components/css/login.css'
import './components/css/navbar.css'
import './components/css/pages.css'
import './components/css/modal.css'
import './components/css/chart.css'
import './components/css/mobile.css'
import './components/css/responsive.css'
import { Provider } from 'react-redux'; 
import { setupStore } from './redux/store'; 
const store = setupStore()
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
