import React from 'react'

function SettingSvg() {
  return (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        fill="none"
    >
        <path
        className='svg-color'
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.5}
        d="M16 20a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
        />
        <path
        className='svg-color'
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.5}
        d="M2.667 17.173v-2.346A2.54 2.54 0 0 1 5.2 12.293c2.413 0 3.4-1.706 2.187-3.8-.694-1.2-.28-2.76.933-3.453l2.307-1.32c1.053-.627 2.413-.253 3.04.8l.146.253c1.2 2.094 3.174 2.094 4.387 0l.147-.253c.626-1.053 1.986-1.427 3.04-.8l2.306 1.32a2.532 2.532 0 0 1 .934 3.453c-1.214 2.094-.227 3.8 2.186 3.8a2.54 2.54 0 0 1 2.534 2.534v2.346a2.54 2.54 0 0 1-2.534 2.534c-2.413 0-3.4 1.706-2.186 3.8a2.529 2.529 0 0 1-.934 3.453l-2.306 1.32c-1.054.627-2.414.253-3.04-.8l-.147-.253c-1.2-2.094-3.173-2.094-4.387 0l-.146.253c-.627 1.053-1.987 1.427-3.04.8L8.32 26.96a2.532 2.532 0 0 1-.933-3.453c1.213-2.094.226-3.8-2.187-3.8a2.54 2.54 0 0 1-2.533-2.534Z"
        />
    </svg>
  )
}

export default SettingSvg
