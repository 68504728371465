import React, { useEffect } from 'react'
import MainTop from '../components/MainTop'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxTypes } from '../type_models/ReduxTypes'
import SingleGraficView from '../components/SingleGraficView'
import TableView from '../components/TableView'
import { changeStateValue } from '../redux/MainReducer'

function CreditorPage() {
  const tableVisible = useSelector((state:ReduxTypes)=> state.Data.tableVisible)
  const dispatch = useDispatch<any>();
  useEffect(() => {
    return () => {
      dispatch(changeStateValue({ name: "tableVisible", value: false }));
    };
  }, []);
  return (
    <>
      <MainTop/>
      {
        tableVisible?  <TableView/> : <SingleGraficView/>
      }
    </>
  )
}

export default CreditorPage
