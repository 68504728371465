import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import EyesSlishSvg from '../svg/EyesSlishSvg';
import EyesShowSvg from '../svg/EyesShowSvg';

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="container">
      <div className="login-container">
      <div className="left-panel">
        <h1>Reporto</h1>
        <p>
        Lorem ipsum dolor sit ame onsectetur.Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit ame onsectetur.
        </p>
      </div>
      <div className="right-panel">
        <h2>Giriş</h2>
        <form>
          <div className="input-group">
            <label htmlFor="username">İstifadəçi adı</label>
            <div className='input'>
              <input  type="text" id="username" placeholder="İstifadəçi adı" />
            </div>
          </div>
          <div className="input-group">
            <label htmlFor="password">Şifrə</label>
            <div className='input'>
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                placeholder="Şifrə"
              />
              <button
                type="button"
                className="toggle-password"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? 'goster' : <EyesSlishSvg/>  }
              </button>
            </div>
          </div>
          <button type="submit" className="login-button">Daxil ol</button>
        </form>
        <Link to="/forgetpassword" className="link">
          Şifrəni unutmusunuz?
        </Link>
      </div>
    </div>
    </div>
  );
};

export default Login;
