import React from 'react'

function CoinSvg() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
  >
    <path
      className='svg-color'
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M24.667 16.867V21.8c0 4.16-3.88 7.533-8.667 7.533S7.333 25.96 7.333 21.8v-4.933C7.333 21.027 11.213 24 16 24s8.667-2.973 8.667-7.133Z"
    />
    <path
      className='svg-color'
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M24.667 10.2a6.272 6.272 0 0 1-.92 3.293c-1.427 2.347-4.36 3.84-7.747 3.84-3.387 0-6.32-1.493-7.747-3.84a6.272 6.272 0 0 1-.92-3.293c0-2.08.974-3.96 2.534-5.32C11.44 3.507 13.6 2.667 16 2.667s4.56.84 6.133 2.2c1.56 1.373 2.534 3.253 2.534 5.333Z"
    />
    <path
      className='svg-color'
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M24.667 10.2v6.667c0 4.16-3.88 7.133-8.667 7.133s-8.667-2.973-8.667-7.133V10.2c0-4.16 3.88-7.533 8.667-7.533 2.4 0 4.56.84 6.133 2.2 1.56 1.373 2.534 3.253 2.534 5.333Z"
    />
  </svg>
  )
}

export default CoinSvg
