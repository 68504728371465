import React, { useState } from 'react'

function Months() {
  const [ activeBtn, setActiveBtn] = useState<string>('last_30')
  return (
    <div className='months'>
      <ul className='months-list'>
        <li onClick={()=> setActiveBtn('last_30')} className={activeBtn == 'last_30' ? 'active-list': ''}>
          <span>Son 30 gün</span>
        </li>
        <li onClick={()=> setActiveBtn('current_month')} className={activeBtn == 'current_month' ? 'active-list': ''}>
          <span>Cari ay</span>
        </li>
        <li onClick={()=> setActiveBtn('last_month')}  className={activeBtn == 'last_month' ? 'active-list': ''}>
          <span>Keçen ay</span>
        </li>
      </ul>
    </div>
  )
}

export default Months
