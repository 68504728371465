import React, { useState } from 'react'
import {  NavLink } from 'react-router-dom'
import HomeSvg from './svg/HomeSvg'
import BankSvg from './svg/BankSvg'
import MoneySendSvg from './svg/MoneySendSvg'
import MoneyReceivSvg from './svg/MoneyReceivSvg'
import WalletRemoveSvg from './svg/WalletRemoveSvg'
import WalletCheckSvg from './svg/WalletCheckSvg'
import CoinSvg from './svg/CoinSvg'
import StatusUpSvg from './svg/StatusUpSvg'
import TagSvg from './svg/TagSvg'
import SettingSvg from './svg/SettingSvg'
import Percentage from './svg/Percentage'
import ChartSvg from './svg/ChartSvg'
import WalletSvg from './svg/WalletSvg'
import ThumbtackSvg from './svg/ThumbtackSvg'
import { useDispatch } from 'react-redux'
import { changeStateValue } from '../redux/MainReducer'


function Navbar() {
  const dispatch = useDispatch<any>();
  return (
    <div className='nav-container'>
      <nav className="navbar">
        <div className="nav-logo">
          <h1>Reporto</h1>
          <button onClick={()=>dispatch(changeStateValue({name:"navVisible", value: false}))} style={{backgroundColor:'transparent'}}>
            <ThumbtackSvg/>
          </button>
        </div>
        <ul className='nav-list'>
          <li>
            <NavLink onClick={()=>dispatch(changeStateValue({name:"navVisible", value: false}))} to='/' >
              <HomeSvg />
              <span>Ana səhifə</span>
            </NavLink>
          </li>
          <li>
            <NavLink onClick={()=>dispatch(changeStateValue({name:"navVisible", value: false}))} to='/cash' >
              <WalletSvg />
              <span>Kassa</span>
            </NavLink>
          </li>
          <li>
            <NavLink onClick={()=>dispatch(changeStateValue({name:"navVisible", value: false}))} to='/noinfo' >
              <BankSvg />
              <span> Bank</span>
            </NavLink>
          </li>
          <li>
            <NavLink onClick={()=>dispatch(changeStateValue({name:"navVisible", value: false}))} to='/l' >
              <MoneySendSvg />
              <span>Debitor</span>
            </NavLink>
          </li>
          <li>
            <NavLink onClick={()=>dispatch(changeStateValue({name:"navVisible", value: false}))} to='/creditor' >
              <MoneyReceivSvg />
              <span>Kreditor</span>
            </NavLink>
          </li>
          <li>
            <NavLink onClick={()=>dispatch(changeStateValue({name:"navVisible", value: false}))} to='/l' >
              <WalletRemoveSvg />
              <span>Pul silinmə</span>
            </NavLink>
          </li>
          <li>
            <NavLink onClick={()=>dispatch(changeStateValue({name:"navVisible", value: false}))} to='/l' >
              <WalletCheckSvg />
              <span>Pul mədaxil</span>
            </NavLink>
          </li>
          <li>
            <NavLink onClick={()=>dispatch(changeStateValue({name:"navVisible", value: false}))} to='/l' >
              <CoinSvg />
              <span>Anbar qalığı</span>
            </NavLink>
          </li>
          <li>
            <NavLink onClick={()=>dispatch(changeStateValue({name:"navVisible", value: false}))} to='/l' >
              <StatusUpSvg />
              <span>İstəhsal</span>
            </NavLink>
          </li>
          <li>
            <NavLink onClick={()=>dispatch(changeStateValue({name:"navVisible", value: false}))} to='/l' >
              <TagSvg />
              <span>Alışlar</span>
            </NavLink>
          </li>
          <li>
            <NavLink onClick={()=>dispatch(changeStateValue({name:"navVisible", value: false}))} to='/l' >
              <SettingSvg />
              <span>Satışlar</span>
            </NavLink>
          </li>
          <li>
            <NavLink onClick={()=>dispatch(changeStateValue({name:"navVisible", value: false}))} to='/l' >
              <Percentage />
              <span>Vergilər</span>
            </NavLink>
          </li>
          <li>
            <NavLink onClick={()=>dispatch(changeStateValue({name:"navVisible", value: false}))} to='/l' >
              <ChartSvg />
              <span>Xərclər</span>
            </NavLink>
          </li>
      </ul>
      </nav>
    </div>
  )
}

export default Navbar
