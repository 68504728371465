import React from 'react'

function NoInformation() {
  return (
    <div className='no-information'>
      <h2>Məlumat yoxdur</h2>
    </div>
  )
}

export default NoInformation
