import React from 'react'
import { NavLink } from 'react-router-dom'
import BuildingSvg from '../svg/BuildingSvg'
import CalendarSvg from '../svg/CalendarSvg'
import Home2Svg from '../svg/Home2Svg'
import ClipboardSvg from '../svg/ClipboardSvg'
import Setting2Svg from '../svg/Setting2Svg'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxTypes } from '../../type_models/ReduxTypes'
import { changeStateValue } from '../../redux/MainReducer'

function MobilTabBar() {
    const modalSelectVisible = useSelector((state:ReduxTypes)=> state.Data.modalSelectVisible)
    const modalSettingVisible = useSelector((state:ReduxTypes)=> state.Data.modalSettingVisible)
    const modalInfoVisible = useSelector((state:ReduxTypes)=> state.Data.modalInfoVisible)
    const dispatch = useDispatch<any>();

return (
    <div className='mobile-tab-bar'>
    <div className="tab-bar">
        <ul>
            <li>
                <button onClick={()=>dispatch(changeStateValue({name:"modalSelectVisible", value: true}))}>
                    <BuildingSvg/>
                </button>
            </li>
            <li>
                <button>
                    <CalendarSvg/>
                </button>
            </li>
            <li className='home-tab'>
                <div className="home-tab-bar">
                    <NavLink to='/'>
                        <Home2Svg/>
                    </NavLink>
                </div>
            </li>
            <li>
                <button onClick={()=>dispatch(changeStateValue({name:"modalInfoVisible", value: true}))}>
                    <ClipboardSvg/>
                </button>
            </li>
            <li>
                <button onClick={()=>dispatch(changeStateValue({name:"modalSettingVisible", value: true}))}>
                    <Setting2Svg/>
                </button>
            </li>
        </ul>
    </div>
    </div>
)
}

export default MobilTabBar
