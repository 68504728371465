import React from "react";
import { PieChart, Pie, Cell, Tooltip } from "recharts";

const data = [
  { name: "Diger xercler", value: 9236548, color: "#F5C242" },
  { name: "Diger xercler", value: 1696548, color: "#F2A73B" },
  { name: "Diger xercler", value: 1396548, color: "#EA334B" },
  { name: "Diger xercler", value: 129248, color: "#AE59DC" },
  { name: "Diger xercler", value: 1196548, color: "#5B61D6" },
  { name: "Diger xercler", value: 1696548, color: "#57BEB5" },
  { name: "Diger xercler", value: 192348, color: "#3070F5" },
];

// Custom Tooltip for styling
const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    const { name, value } = payload[0].payload;

    return (
      <div style={{
        backgroundColor: '#EDEFF1', 
        borderRadius: '12px', 
        padding: '8px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        zIndex: 4
      }}>
        <div style={{ marginBottom: '5px', fontWeight: 'bold' }}>
          {name}
        </div>
        <div>
          {value.toFixed(2)} ₼
        </div>
      </div>
    );
  }
  return null;
};

const DonutChartExample = () => {
  return (
    <div className="chart-card-circle chart-container" style={{backgroundColor:'white'}}>
      <div style={{ textAlign: "center", position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <PieChart width={window.innerWidth <= 310 ? 200 : 250} height={window.innerWidth <= 310 ? 200 : 250}>
          <Pie
            data={data}
            dataKey="value"
            nameKey="name"
            innerRadius={window.innerWidth <= 310 ? 70 : 85}
            outerRadius={window.innerWidth <= 310 ? 90 : 110}
            fill="#8884d8"
            paddingAngle={1}  // Control the slice gap here
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
        </PieChart>
        <div className='pieChartText'  style={{
          position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", zIndex: 0
        }}>
          <h3>Total Value</h3>
          <p>$9,999.99</p>
        </div>
      </div>

      <div style={{ marginTop: 17 }}>
        {/* Scrollable legend for remaining items */}
        <div style={{ maxHeight: '150px', overflowY: 'auto', padding: '8px 0' }} className="donut-scroll">
          {data.map((entry, index) => (
            <div key={index} className="donut-line" style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
              <div style={{ width: 20, height: 16, backgroundColor: entry.color, marginRight: 10 }}></div>
              <span>{entry.name}</span>
              <span style={{ marginLeft: "auto" }}>{entry.value.toFixed(2)} ₼</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DonutChartExample;
