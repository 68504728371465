import React, { useState } from 'react'
import NoInformation from '../components/NoInformation'

function NoInformationPage() {
  const companies = [
    { id: 1, name: 'Saat store' },
    { id: 2, name: 'Runi MMC' },
    { id: 3, name: 'Siesco MMC' },
    { id: 4, name: 'SAFS MMC' },
    { id: 5, name: 'Playstation Club' },
  ];
 
  return (
  <>
    <NoInformation/>
  </>
  )
}

export default NoInformationPage
