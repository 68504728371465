import React from 'react'

function DiagramSvg() {
  return (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={30}
        height={30}
        fill="none"
    >
        <path
        stroke="#FAFAFA"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.5}
        d="M2.5 2.5v21.25a3.745 3.745 0 0 0 3.75 3.75H27.5"
        />
        <path
        stroke="#FAFAFA"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.5}
        d="m6.25 21.25 5.738-6.7c.95-1.1 2.637-1.175 3.662-.138l1.187 1.188a2.504 2.504 0 0 0 3.663-.137l5.75-6.713"
        />
    </svg>
  )
}

export default DiagramSvg
