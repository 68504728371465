import React from 'react'

function MoneyReceivSvg() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
  >
    <path
      className='svg-color'
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M12.667 18.333c0 1.294 1 2.334 2.226 2.334H17.4c1.067 0 1.933-.907 1.933-2.04 0-1.214-.533-1.654-1.32-1.934L14 15.293c-.787-.28-1.32-.706-1.32-1.933 0-1.12.867-2.04 1.933-2.04h2.507c1.227 0 2.227 1.04 2.227 2.333M16 10v12"
    />
    <path
      className='svg-color'
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M29.333 16c0 7.36-5.973 13.333-13.333 13.333S2.667 23.36 2.667 16 8.64 2.667 16 2.667M22.667 4v5.333H28M29.333 2.667l-6.666 6.666"
    />
  </svg>
  )
}

export default MoneyReceivSvg
