import React from 'react'

function ClipboardSvg() {
  return (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={25}
        fill="none"
    >
        <path
        className='tabbar-svg'
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.5}
        d="M8 12.7h7M8 16.7h4.38M10 6.5h4c2 0 2-1 2-2 0-2-1-2-2-2h-4c-1 0-2 0-2 2s1 2 2 2Z"
        />
        <path
        className='tabbar-svg'
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.5}
        d="M16 4.52c3.33.18 5 1.41 5 5.98v6c0 4-1 6-6 6H9c-5 0-6-2-6-6v-6c0-4.56 1.67-5.8 5-5.98"
        />
  </svg>
  )
}

export default ClipboardSvg
